












import Vue from "vue";


export default Vue.extend({
  name: "venue-app-features-search" as string,
  props: ['foundResults'],


  data() {
    return {
      scrollBarOptions: {
          wheelSpeed: 0.5,
        },
    }
  },
  updated() {
    this.Focus()
  },
  methods: {
    Focus() {
      let i = -1;
      let songs = document.querySelectorAll('.list-group');
      let songsLength = songs.length;
      window.addEventListener('keydown', (e) => {

        if (e.key === 'ArrowDown') {
          e.preventDefault()
          if (i >= songs.length - 1) {
            return i = songsLength - 1
          }
          i++;
          (songs[i] as HTMLElement)?.focus();

        }

        if (e.key === 'ArrowUp') {
          e.preventDefault()
          if (i <= 0) {
            (document.querySelector(".features-censored-search-bar") as HTMLElement)?.focus();
            return i = -1
          }
          i--;
          (songs[i] as HTMLElement)?.focus();
        }

      })
    },
    Submit(song){
      if(song.title){
        this.$emit('SongTitle',song.title);
      }
      if(song.name){
        this.$emit('ArtistTitle',song.name);
      }
    }

  },


})
