





































































































































































































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import VenueAppFeaturesSearch from "@/router/views/venues/VenueAppFeaturesSearch.vue";
import BackButton from "@/components/back-button.vue";
import { ToggleButton } from "vue-js-toggle-button";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
Object.defineProperty(Vue.prototype, '$_', { value: _ });
import {mapActions, mapGetters} from "vuex";
import {ArtistData, SongData, SubscriptionProductsData, VenueData} from "@/types";
import router from "@/router";

export default Vue.extend({
  name: "venue-app-features" as string,

  components: {
    Layout,
    vSelect,
    ToggleButton,
    'venue-app-features-search' : VenueAppFeaturesSearch,
    "back-button": BackButton
  },

  data() {
    return {
      title: "Songoroo app features",
      activeVenue: {} as VenueData | null,
      allVenues: [] as Array<VenueData>,
      searchSongInput: "",
      searchArtistInput: "",
      featureOptions: [
        {
          name: '1',
          value: 1
        },
        {
          name: '2',
          value: 2
        },
        {
          name: '3',
          value: 3
        },
        {
          name: '4',
          value: 4
        },
        {
          name: '5',
          value: 5
        },
        {
          name: '6',
          value: 6
        },
        {
          name: '7',
          value: 7
        },
        {
          name: '8',
          value: 8
        },
        {
          name: '9',
          value: 9
        },
        {
          name: '10',
          value: 10
        },
        {
          name: `${this.$t('Unlimited')}`,
          value: 999999
        },
      ],
      isSearching: false,
      router: router
    }
  },
async mounted(): Promise<void> {
  await this.setVenues({fetchRatings: false, name: ''});
  await this.setVenue(Number(router.currentRoute.params.id));
  if (!!this.selectedVenue.image_last_updated) {
    await this.setImage({id: this.selectedVenue.id, imageType: "clientzone_venue"});
  }

  await this.setVenueSubscription(router.currentRoute.params.id);
  await this.setSubscriptionProducts();

  if (this.getSubscriptionType === 2 || this.getSubscriptionType === 3) {
    await this.setCensoredSongs(Number(router.currentRoute.params.id));
    await this.setCensoredArtists(Number(router.currentRoute.params.id));

    await this.fetchCensoredArtistsImages();
    await this.fetchCensoredSongsImages();
  }

  this.allVenues = this.venues;
  this.activeVenue = this.selectedVenue;
},

computed: {
  ...mapGetters("venueModule", {
    venue: "GET_SELECTED_VENUE",
    venues: "GET_VENUES",
    selectedVenueId: "GET_SELECTED_VENUE_ID"
  }),
  ...mapGetters("companyModule", {
    companyId: "GET_COMPANY_ID"
  }),
  ...mapGetters("venueAppFeaturesModule", {
    selectedVenue: "GET_SELECTED_VENUE",
    // appFeatures: "GET_APP_FEATURES",
    // venueTypeFeatures: "GET_VENUE_TYPE_FEATURES",
    finalFeatures: "GET_VENUE_FINAL_FEATURES",
    foundSongs: "GET_FOUND_SONGS",
    foundArtists: "GET_FOUND_ARTISTS",
    censoredSongs: "GET_CENSORED_SONGS",
    censoredArtists: "GET_CENSORED_ARTISTS"
  }),
  ...mapGetters("venueSubscriptionModule", {
    subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
    selectedVenueSubscription: "GET_SELECTED_VENUE_SUBSCRIPTION"
  }),
  insertVenueImage(): string {
    if (!this.venue.image) return "";
    return `data:image/jpeg;charset=utf-8;base64, ${this.venue.image}`;
  },
  explicitContentFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "censor_explicit")) return false;
      return this.finalFeatures.find(f => f.type === "censor_explicit").value;
  },
  votingFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "voting")) return false;
      return this.finalFeatures.find(f => f.type === "voting").value;
  },
  playlistLimitFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "playlist_limit")) return false;
      return this.finalFeatures.find(f => f.type === "playlist_limit").value;
  },
  addSongFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "add_song")) return false;
      return this.finalFeatures.find(f => f.type === "add_song").value;
  },
  boostFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "boost")) return false;
      return this.finalFeatures.find(f => f.type === "boost").value;
  },
  songForYouFeature(): boolean | number | undefined {
      if (!this.finalFeatures.find(f => f.type === "song_for_you")) return false;
      return this.finalFeatures.find(f => f.type === "song_for_you").value;
  },
  isSubscriptionProductPremium(): boolean {
    if (!this.subscriptionProducts.length || !this.selectedVenueSubscription.stripeProductWithPriceId) return false;
    const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId);

    return product.productName === "Premium";
  },
  isTrial(): boolean {
    if (!this.selectedVenueSubscription) return false;

    let currentTimestamp: number = Math.floor(Date.now() / 1000);
    let subscriptionEndTimestamp: number = parseInt(this.selectedVenueSubscription.subscriptionEndsAt);

    return (this.selectedVenueSubscription.isTrial === true && currentTimestamp < subscriptionEndTimestamp);
  },
  getSubscriptionType(): number {
    if (!this.selectedVenueSubscription) return -1;

    const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId);
    if (product){
      return product.songorooPackageType;
    } else if (this.selectedVenueSubscription.isCorporate && !this.selectedVenueSubscription.stripeProductWithPriceId){
      return 3;
    } else if (this.isTrial) {
      return 2;
    } else {
      return -1;
    }
  },
  lan(){
      return this.$i18n.locale
  },
},

methods: {
  ...mapActions("venueModule", {
    setVenues: "FETCH_VENUES",
    resetVenueImage: "RESET_VENUE_IMAGE"
  }),
  ...mapActions("venueAppFeaturesModule", {
    setVenue: "FETCH_VENUE",
    setVenueFeatures: "FETCH_VENUE_FEATURES",
    updateVenueFeature: "UPDATE_VENUE_FEATURE",
    searchSongs: "SEARCH_SONGS",
    searchArtists: "SEARCH_ARTISTS",
    setCensoredSongs: "FETCH_CENSORED_SONGS",
    setCensoredArtists: "FETCH_CENSORED_ARTISTS",
    updateCensored: "UPDATE_CENSORED",
    addCensoredSongs: "ADD_CENSORED_SONGS",
    resetSearchResults: "RESET_SEARCH_RESULTS"
  }),
  ...mapActions("globalModule", {
    setImage: "FETCH_ENTITY_IMAGE"
  }),
  ...mapActions("venueSubscriptionModule", {
    setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
    setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION"
  }),
  async setSelectedVenue(venueId: number): Promise<void> {
    await this.setVenue(venueId);
    localStorage.setItem('venue_id',venueId.toString());
    if (!!this.selectedVenue.image_last_updated) {
      await this.setImage({id: venueId, imageType: "clientzone_venue"});
    } else {
      await this.resetVenueImage();
    }

    await this.setVenueSubscription(venueId);

    if (this.getSubscriptionType === 2 || this.getSubscriptionType === 3) {
      await this.setCensoredSongs(venueId);
      this.fetchCensoredSongsImages();
      await this.setCensoredArtists(venueId);
      this.fetchCensoredArtistsImages();

      this.searchSongInput = "";
      this.searchArtistInput = "";
      this.resetSearchResults();
    }
      this.$router.push({
        path: '/app-features-setup/' + venueId,
        params: { id: venueId }
      })
  },
  async updateFeature(featureValue, type: string): Promise<void> {
    const payload: object = {
      type: type,
      value: featureValue ? 1 : 0
    }

    await this.updateVenueFeature(payload);
  },
  async updateFeatureOption(featureOptionValue: number, type: string): Promise<void> {
    const payload: object = {
      type: type,
      value: featureOptionValue
    }

      await this.updateVenueFeature(payload);
    },
    searchSongName: _.debounce(async function(): Promise<void> {
      await this.searchSongs(this.searchSongInput);
      this.fetchFoundSongsImages();
      if(this.foundSongs.length && this.searchSongInput.length > 0){
        this.isSearching = true;
      } else{
        this.isSearching = false;
      }
    }, 500),
    searchArtistName: _.debounce(async function(): Promise<void> {
      await this.searchArtists(this.searchArtistInput);
      if(this.foundArtists.length && this.searchArtistInput.length > 0){
        this.isSearching = true;
      } else{
        this.isSearching = false;
      }
      this.fetchFoundArtistsImages();
    }, 500),
    async removeFromCensored(id: number, type: string): Promise<void> {
      await this.updateCensored({id: id, type: type});
      if (type === "censor_song") this.fetchCensoredSongsImages();
      else this.fetchCensoredArtistsImages();
    },
    async addToCensored(id: number, type: string): Promise<void> {
      await this.updateCensored({id: id, isCensored: false, type: type});
      if (type === "censor_song") this.fetchCensoredSongsImages();
      else this.fetchCensoredArtistsImages();
    },
    fetchCensoredArtistsImages(): void {
      for (let i = 0; i < this.censoredArtists.length; i++) {
        const censoredArtist: ArtistData = this.censoredArtists[i];

        if (!!censoredArtist.image_last_updated) {
          this.setImage({id: censoredArtist.id, imageType: "artist", multi: true});
        }
      }
    },
    fetchFoundArtistsImages(): void {
      for (let i = 0; i < this.foundArtists.length; i++) {
        const foundArtist: ArtistData = this.foundArtists[i];

        if (!!foundArtist.image_last_updated) {
          this.setImage({id: foundArtist.id, imageType: "artist", multi: true, found: true});
        }
      }
    },
    fetchCensoredSongsImages(): void {
      for (let i = 0; i < this.censoredSongs.length; i++) {
        const censoredSong: SongData = this.censoredSongs[i];

        if (!!censoredSong.artist_image_last_updated) {
          this.setImage({id: censoredSong.artist_id, imageType: "artist", multi: true, song: true});
        }
      }
    },
    fetchFoundSongsImages(): void {
      for (let i = 0; i < this.foundSongs.length; i++) {
        const foundSong: SongData = this.foundSongs[i];

        if (!!foundSong.artist_image_last_updated) {
          this.setImage({id: foundSong.artist_id, imageType: "artist", multi: true, song: true, found: true});
        }
      }
    },
    insertArtistImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    isSongAlreadyCensored(id: number): boolean {
      return this.censoredSongs.find(s => s.id === id);
    },
    isArtistAlreadyCensored(id: number): boolean {
      return this.censoredArtists.find(a => a.id === id);
    },
    async SongTitle(value) {
      if (this.searchSongInput != value) {
        this.searchSongInput = value;
        await this.searchSongs(this.searchSongInput);
        this.fetchFoundSongsImages();
        this.isSearching = false;
      }
    },
    async ArtistTitle(value) {
      if (this.searchArtistInput != value) {
        this.searchArtistInput = value;
        await this.searchArtists(this.searchArtistInput);
        this.fetchFoundArtistsImages();
        this.isSearching = false;
      }
    },
    Blur() {
      window.addEventListener('click', (e) => {
        if ((e.target as HTMLInputElement).classList.contains("search-result-songs")) {
          return false;
        } else {
          this.isSearching = false
        }
      })
    },
  },

  watch: {
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/venues");
        }
      },
    },
    lan(newValue) {
      this.featureOptions[10].name = 'Unlimited'
      }
  },
  destroyed() {
    this.resetSearchResults();
  },
})
